import { css } from '@emotion/react';
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	height: 100%;

	.braintree-dropin-loading {
		.braintree-dropin {
			display: none;
		}
	}

	.braintree-dropin-wrapper {
		min-height: 174px;
	}

	// .braintree-dropin {
	// 	margin-bottom: ${theme.spacing(3)}px;
	//
	// 	[data-braintree-id='upper-container'] {
	// 		margin-bottom: ${theme.spacing(3)}px;
	// 	}
	//
	// 	.braintree-method {
	// 		border: 1px solid ${grey['200']};
	//
	// 		.braintree-method__icon svg {
	// 			height: 100%;
	// 			width: 100%;
	// 		}
	// 	}
	//
	// 	svg {
	// 		height: 100%;
	// 		width: auto;
	// 	}
	//
	// 	.braintree-delete-confirmation .braintree-delete-confirmation__button-container {
	// 		.braintree-delete-confirmation__button {
	// 			border-top: 1px solid ${grey['200']};
	// 		}
	//
	// 		[data-braintree-id='delete-confirmation__yes'] {
	// 			border-left: none;
	// 		}
	// 	}
	//
	// 	// make close icon smaller
	// 	.braintree-method__delete-container .braintree-method__delete {
	// 		display: flex;
	// 		justify-content: center;
	// 		align-items: center;
	// 		padding: 0;
	// 		svg {
	// 			width: 12px;
	// 			margin-top: 0;
	// 			margin-left: 0;
	// 		}
	// 	}
	//
	// 	.braintree-heading {
	// 		//@include emphasis;
	// 		margin-bottom: ${theme.spacing(1)}px;
	// 	}
	//
	// 	.braintree-placeholder {
	// 		margin-bottom: 0;
	// 	}
	//
	// 	.braintree-upper-container:before {
	// 		border: none;
	// 	}
	//
	// 	.braintree-loader__indicator {
	// 		width: 30px;
	// 		height: 30px;
	//
	// 		.braintree-loader__lock {
	// 			display: none;
	// 		}
	// 	}
	//
	// 	.braintree-options-list {
	// 		padding-bottom: 0;
	//
	// 		.braintree-option {
	// 			background: ${theme.palette.background.paper};
	// 			border-color: ${grey['200']};
	//
	// 			&:last-child {
	// 				border-width: 0 1px 2px 1px;
	// 			}
	// 		}
	// 	}
	//
	// 	.braintree-large-button {
	// 		&:hover {
	// 			background: none;
	// 		}
	// 	}
	//
	// 	.braintree-sheet__container {
	// 		margin-bottom: 0;
	// 	}
	//
	// 	.braintree-sheet {
	// 		border: 1px solid ${grey['200']};
	// 		border-bottom-width: 2px;
	// 		background: transparent;
	// 		overflow: hidden;
	//
	// 		.braintree-sheet__header {
	// 			border-bottom: 1px solid ${grey['200']};
	// 			background: ${theme.palette.background.paper};
	//
	// 			.braintree-sheet__header-label {
	// 				.braintree-sheet__label {
	// 					padding-left: ${theme.spacing(1)}px;
	// 				}
	// 			}
	// 		}
	//
	// 		.braintree-sheet__content--form {
	// 			> .braintree-form__field-group {
	// 				margin-bottom: ${theme.spacing(1)}px;
	// 			}
	// 		}
	//
	// 		.braintree-form__field-group {
	// 			margin-bottom: 0;
	//
	// 			.braintree-form__label {
	// 				//@include emphasis;
	// 				margin-bottom: ${theme.spacing(1)}px;
	//
	// 				.braintree-form__descriptor {
	// 					//@include emphasis;
	// 					color: ${grey['500']};
	// 				}
	// 			}
	//
	// 			.braintree-form__field {
	// 				display: inline-block;
	//
	// 				.braintree-form__hosted-field {
	// 					border: none;
	// 					border-bottom: 2px solid ${grey['200']};
	// 					font-weight: 400;
	// 					position: relative;
	// 					display: inline-block;
	// 					-webkit-appearance: none;
	// 					-moz-appearance: none;
	// 					appearance: none;
	// 					padding: 0 ${theme.spacing(2)}px;
	// 					// fixed input height
	// 					height: 45px;
	// 					border-radius: ${theme.shape.borderRadius};
	// 					font-size: ${theme.typography.fontSize}px;
	// 					white-space: nowrap;
	// 					overflow: hidden;
	// 					text-overflow: ellipsis;
	// 					line-height: 1.5;
	// 					transition: border ${theme.transitions.easing.easeInOut} 0.2s;
	// 					background: ${theme.palette.background.paper};
	// 				}
	// 			}
	//
	// 			label {
	// 				margin-bottom: 0;
	// 				display: block;
	// 			}
	//
	// 			&.braintree-form__field-group--has-error {
	// 				label {
	// 					margin-bottom: ${theme.spacing(1)}px;
	// 				}
	//
	// 				.braintree-form__field-error {
	// 					margin-bottom: ${theme.spacing(1)}px;
	// 					margin-top: 0;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
`;
