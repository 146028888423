import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	&,
	.checkout-wrapper {
		height: 100%;
	}

	.checkout-steps {
		padding: ${theme.spacing(0, 4)};

		${theme.breakpoints.down('lg')} {
		  padding: ${theme.spacing(2, 4)};
		}
	}
`;
