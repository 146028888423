import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Grid,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { Checkout } from '@models/checkout';
import { Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import { UserState } from '@store/user/types';
import { formatAddress, getCardColour } from '@helpers/common';
import { cardCost, getShippingCode, getShippingCost, getTotalCost } from '@helpers/checkout';
import { makeGetRequest } from '@helpers/requests';
import { ADDRESSES } from '@helpers/api';
import { Addresses } from '@models/address';
import { useSnackbar } from '@components/common/Snackbar';
import { Link as RouterLink } from 'gatsby';

const Complete: React.FC<{ checkout: Checkout }> = ({ checkout }) => {
	const { user } = useSelector(({ user }) => ({ user }));
	const [addresses, setAddresses] = useState<Addresses>([]);
	const [openSnackbar] = useSnackbar();
	const shippingAddress = addresses?.find(({ _id }) => _id === checkout.shippingAddressId);
	const billingAddress = addresses?.find(({ _id }) => _id === checkout.billingAddressId);
	const shippingCode = getShippingCode(checkout.shippingCode);
	const shippingCost = getShippingCost(checkout.shippingCode);

	useEffect(() => {
		(async () => {
			try {
				const { data: adressesData } = await makeGetRequest(ADDRESSES);
				setAddresses(adressesData);
			} catch (error) {
				if (error !== 'cancelled') {
					openSnackbar(
						error?.errorMessage ??
							'An error occurred attempting to retrieve your addresses.'
					);
				}
			}
		})();
	}, []);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant="h1">
					Order complete <CheckCircle />
				</Typography>
				<Typography>Your card will be with you as soon as possible!</Typography>

				<Box mt={3} />

				<Alert severity="success">
					Your order has been successful, <b>please check your email at {user?.email}</b>{' '}
					for an order receipt and a welcome email to finish registering your account, or
					complete your account below.
				</Alert>

				<Box mt={3} />

				<Paper>
					<List style={{ height: 345, overflowY: 'auto' }}>
						<ListItem>
							<ListItemText primary="Order id" secondary={checkout._id} />
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Card color"
								secondary={getCardColour(checkout.card.colour)}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Card line one"
								secondary={checkout.card.lineOne.toUpperCase()}
							/>
						</ListItem>
						{!!checkout.card.lineTwo && (
							<ListItem>
								<ListItemText
									primary="Card line one"
									secondary={checkout.card.lineTwo.toUpperCase()}
								/>
							</ListItem>
						)}
						<ListItem>
							<ListItemText
								primary="Billing address"
								secondary={formatAddress(billingAddress)}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Shipping address"
								secondary={formatAddress(shippingAddress)}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Shipping type"
								secondary={shippingCode.name ?? ''}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Shipping cost"
								secondary={shippingCost ? `$${shippingCost} USD` : 'Free'}
							/>
						</ListItem>
						<ListItem>
							<ListItemText primary="Subtotal" secondary={`$${cardCost} USD`} />
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Total"
								secondary={`$${getTotalCost(checkout.shippingCode)} USD`}
								secondaryTypographyProps={{
									style: { fontWeight: 700 },
								}}
							/>
						</ListItem>
					</List>
				</Paper>

				<Box mt={3} />
				<Grid container justifyContent="flex-end">
					<Grid item>
						<Button
							component={RouterLink}
							to={`/dashboard/cards`}
							variant="contained"
						>
							View your cards
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Complete;
