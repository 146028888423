import React, { useEffect, useState } from 'react';
import { Field, Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from '@store/user/types';
import styles from './styles';
import {
	Box,
	Button,
	CircularProgress,
	Fade,
	Grid,
	Typography,
	useMediaQuery,
} from '@mui/material';
import * as Yup from 'yup';
import { useTheme } from '@mui/styles';
import { TextField } from 'formik-mui';
import { makeGetRequest, makePostRequest } from '@helpers/requests';
import { USER_EXISTS, USER_LOGIN, USER_REGISTER } from '@helpers/api';
import { loginUser } from '@store/user/actions';
import { useSnackbar } from '@components/common/Snackbar';
import Loader from '@components/common/Loader';
import { capitalise } from '@helpers/common';

const Login: React.FC<{ onContinue: () => void; onBack: () => void }> = ({
	onContinue,
	onBack,
}) => {
	const theme = useTheme();
	const { values: checkoutValues, setFieldValue: onChangeFieldValue }: any = useFormikContext();
	const { user } = useSelector(({ user }) => ({ user }));
	const [showEmailTimeout, setShowEmailTimeout] = useState<any>(null);
	const [showEmail, setShowEmail] = useState<boolean>(true);
	const [showRegister, setShowRegister] = useState<boolean>(false);
	const [showRegisterTimeout, setShowRegisterTimeout] = useState<any>(null);
	const [showPasswordTimeout, setShowPasswordTimeout] = useState<any>(null);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [verifying, setVerifying] = useState<boolean>(false);
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));
	const dispatch = useDispatch();
	const [openSnackbar] = useSnackbar();

	console.log({ checkoutValues });

	// skip this step if user exists (or user has logged in/was created)
	useEffect(() => {
		if (user) {
			onContinue();
			return;
		}
	}, [user]);

	const initialValues: {
		userExists: boolean;
		email: string;
		password: string | null;
		firstName: string | null;
		lastName: string | null;
	} = {
		userExists: false,
		email: '',
		password: '',
		firstName: '',
		lastName: '',
	};

	// login user/create new user
	const handleSubmit = async (values) => {
		try {
			let countryCode = 'US';

			if (!values.userExists) {
				const { data: ipData } = await makeGetRequest('https://ipapi.co/json/');
				if (!!ipData?.country_code) countryCode = ipData?.country_code;
			}

			const { data } = !values.userExists
				? await makePostRequest(USER_REGISTER, {
						firstName: values.firstName,
						lastName: values.lastName,
						email: values.email,
						concierge: true,
						countryCode: countryCode,
				  })
				: await makePostRequest(USER_LOGIN, {
						email: values.email,
						password: values.password,
				  });
			await dispatch(loginUser(data));

			setShowEmail(false);
			setShowPassword(false);
			setTimeout(() => onContinue(), 500);
		} catch (error) {
			openSnackbar(error?.errorMessage ?? 'An error occurred attempting to log in.', 'error');
		}
	};

	useEffect(() => {
		return () => {
			clearTimeout(showPasswordTimeout);
			clearTimeout(showRegisterTimeout);
			clearTimeout(showEmailTimeout);
		};
	}, []);

	if (!!user) {
		return <Loader />;
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				userExists: Yup.boolean(),
				email: Yup.string().email().required().label('Email'),
				firstName: Yup.mixed()
					.nullable()
					.when('userExists', {
						is: false,
						then: Yup.string().required().label('First name'),
					}),
				lastName: Yup.mixed()
					.nullable()
					.when('userExists', {
						is: false,
						then: Yup.string().required().label('Last name'),
					}),
				password: Yup.mixed()
					.nullable()
					.when('userExists', {
						is: true,
						then: Yup.string().required().label('Password'),
					}),
			})}
			onSubmit={handleSubmit}
		>
			{({ handleSubmit, values, isSubmitting, setFieldValue }) => {
				const handleEmailVerification = async () => {
					try {
						setVerifying(true);

						const { data: userExistsData } = await makePostRequest(USER_EXISTS, {
							email: values.email,
						});

						if (userExistsData?.exists) {
							handleLoginNext();
						} else {
							handleRegisterNext();
						}

						setFieldValue('userExists', userExistsData?.exists ?? false);
						setVerifying(false);
					} catch (error) {
						setVerifying(false);
					}
				};

				const handleLoginNext = () => {
					console.log('email');
					setShowEmail(false);
					setShowPasswordTimeout(setTimeout(() => setShowPassword(true), 500));
				};

				const handleRegisterNext = () => {
					setFieldValue(
						'firstName',
						capitalise(
							values.firstName || checkoutValues?.card?.lineOne?.split(' ')[0] || ''
						)
					);
					setFieldValue(
						'lastName',
						capitalise(
							values.lastName ||
								(checkoutValues?.card?.lineOne?.split(' ') &&
									checkoutValues?.card?.lineOne?.split(' ')?.length > 1)
								? checkoutValues?.card?.lineOne?.split(' ').pop() || ''
								: ''
						)
					);
					setShowEmail(false);
					setShowRegisterTimeout(setTimeout(() => setShowRegister(true), 500));
				};

				const handlePasswordBack = () => {
					setShowPassword(false);
					setShowEmailTimeout(
						setTimeout(() => {
							setShowEmail(true);
						}, 500)
					);
				};
				const handleRegisterBack = () => {
					setShowRegister(false);
					setShowEmailTimeout(
						setTimeout(() => {
							setShowEmail(true);
						}, 500)
					);
				};

				return (
					<Grid
						css={styles(theme)}
						container
						spacing={4}
						direction={desktop ? 'row' : 'column'}
						justifyContent="space-between"
						wrap={desktop ? 'wrap' : 'nowrap'}
					>
						<Grid item xs lg={12}>
							<Fade unmountOnExit in={showEmail} timeout={{ enter: 500, exit: 250 }}>
								<div>
									<Typography variant="h1">Enter your email address</Typography>
									<Box mt={1} />
									<Typography>
										If you don't have an account with us, enter an email address
										you use, otherwise use your user email address you typically
										login with.
									</Typography>

									<Box mt={4} />
									<Field
										required
										component={TextField}
										label="Email address"
										variant="filled"
										name="email"
										fullWidth
									/>
								</div>
							</Fade>

							<Fade
								unmountOnExit
								in={showPassword}
								timeout={{ enter: 500, exit: 250 }}
							>
								<div>
									<Typography variant="h1">Enter your password</Typography>
									<Box mt={1} />
									<Typography>
										Welcome back {values.email}, please login to continue your
										checkout.
									</Typography>

									<Box mt={4} />
									<Field
										required
										component={TextField}
										label="Password"
										type="password"
										variant="filled"
										name="password"
										fullWidth
									/>
								</div>
							</Fade>
							<Fade
								unmountOnExit
								in={showRegister}
								timeout={{ enter: 500, exit: 250 }}
							>
								<div>
									<Typography variant="h1">Whats your name?</Typography>
									<Box mt={1} />
									<Typography>
										We'll use these details to set up your account.
									</Typography>

									<Box mt={4} />
									<Grid container spacing={2}>
										<Grid item xs={12} md={6}>
											<Field
												required
												component={TextField}
												label="First Name"
												type="text"
												variant="filled"
												name="firstName"
												fullWidth
											/>
										</Grid>

										<Grid item xs={12} md={6}>
											<Field
												required
												component={TextField}
												label="Last Name"
												type="text"
												variant="filled"
												name="lastName"
												fullWidth
											/>
										</Grid>
									</Grid>
								</div>
							</Fade>
						</Grid>
						<Grid item lg={12}>
							<Fade unmountOnExit in={showEmail} timeout={{ enter: 500, exit: 250 }}>
								<Grid container justifyContent="space-between">
									<Grid item>
										<Button color="secondary" onClick={onBack}>
											Back
										</Button>
									</Grid>
									<Grid item>
										<Button

											variant="contained"
											disabled={!values.email || isSubmitting || verifying}
											startIcon={
												isSubmitting || verifying ? (
													<CircularProgress color="inherit" size={20} />
												) : undefined
											}
											onClick={handleEmailVerification}
										>
											Continue
										</Button>
									</Grid>
								</Grid>
							</Fade>

							<Fade
								unmountOnExit
								in={showPassword}
								timeout={{ enter: 500, exit: 250 }}
							>
								<Grid container justifyContent="space-between">
									<Grid item>
										<Button color="secondary" onClick={handlePasswordBack}>
											Back
										</Button>
									</Grid>
									<Grid item>
										<Button

											variant="contained"
											disabled={isSubmitting}
											startIcon={
												isSubmitting ? (
													<CircularProgress color="inherit" size={20} />
												) : undefined
											}
											onClick={() => handleSubmit()}
										>
											Continue
										</Button>
									</Grid>
								</Grid>
							</Fade>

							<Fade
								unmountOnExit
								in={showRegister}
								timeout={{ enter: 500, exit: 250 }}
							>
								<Grid container justifyContent="space-between">
									<Grid item>
										<Button color="secondary" onClick={handleRegisterBack}>
											Back
										</Button>
									</Grid>
									<Grid item>
										<Button

											variant="contained"
											disabled={isSubmitting}
											startIcon={
												isSubmitting ? (
													<CircularProgress color="inherit" size={20} />
												) : undefined
											}
											onClick={() => handleSubmit()}
										>
											Continue
										</Button>
									</Grid>
								</Grid>
							</Fade>
						</Grid>
					</Grid>
				);
			}}
		</Formik>
	);
};

export default Login;
