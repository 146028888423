import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material';
import LogoFull from '@assets/logo/logo-full-transparent.inline.svg';
import LogoBlack from '@assets/card-logo-symbol-1c-black.svg';
import LogoSilver from '@assets/card-logo-symbol-2g-silver.svg';
import LogoWhite from '@assets/card-logo-symbol-white.svg';
import { Box, Fade, Grow, Typography, useMediaQuery } from '@mui/material';
import { CheckoutCard } from '@models/checkout';

const Preview: React.FC<{
	step: string;
	steps: string[];
	card: CheckoutCard;
}> = ({ step, steps, card: { colour, lineOne, lineTwo } }) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.up('lg'));

	return (
		<div css={styles(theme)} className={mobile ? undefined : 'preview-mobile'}>
			<Box
				sx={{
					maxWidth: 540,
					width: { md: '80%', lg: '60%' },
					minWidth: { xs: 'auto', lg: 420 },
					margin: '0 auto',
				}}
			>
				<Box
					className={`card ${
						colour === 'black'
							? 'card-black'
							: colour === 'silver'
							? 'card-silver'
							: colour === 'gold'
							? 'card-gold'
							: ''
					}`}
				>
					<div className="card-logo">
						<img
							src={colour === 'silver' || colour === 'gold' ? LogoBlack : LogoSilver}
							alt="logo"
						/>
					</div>

					{/* <LogoFull className="card-logo" />

					<div className="card-nfc">
						<div className="card-nfc-line" />
						<div className="card-nfc-line" />
						<div className="card-nfc-line" />
					</div> */}

					<div className="card-lines">
						<div
							className={`card-line ${
								steps.indexOf(step) > steps.indexOf('customise') && !lineTwo
									? 'card-line-single'
									: ''
							}`}
						>
							<Fade unmountOnExit in={!lineOne}>
								<div className="card-first-line" />
							</Fade>

							<Fade unmountOnExit in={!!lineOne}>
								<div className="card-first-line card-first-line-filled">
									<Typography noWrap>{lineOne}</Typography>
								</div>
							</Fade>
						</div>

						<Grow in={!(steps.indexOf(step) > steps.indexOf('customise') && !lineTwo)}>
							<div className="card-line">
								<Fade unmountOnExit in={!lineTwo}>
									<div className="card-second-line" />
								</Fade>

								<Box mt={1} />

								<Fade
									unmountOnExit
									in={!!lineTwo}
									timeout={{ enter: 100, exit: 500 }}
								>
									<div className="card-second-line card-second-line-filled">
										<Typography noWrap>{lineTwo}</Typography>
									</div>
								</Fade>
							</div>
						</Grow>
					</div>
				</Box>
			</Box>
		</div>
	);
};

export default Preview;
