import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { amber, grey, yellow } from '@mui/material/colors';

export default (theme: Theme) => css`
	height: 100%;

	.palette-wrapper {
		.palette {
			border-radius: ${theme.shape.borderRadius}px;

			width: 100%;
			height: 40px;

			&.palette-black {
				background-color: ${grey['900']};
			}

			&.palette-silver {
				background-color: ${grey['400']};
			}

			&.palette-gold {
				background-color: ${amber['500']};
			}
		}
	}
`;
