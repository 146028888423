import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Address } from '@models/address';
import { TextField } from 'formik-mui';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField as MuiTextField,
	Typography,
	Autocomplete,
} from '@mui/material';
import { useSnackbar } from '@components/common/Snackbar';
import { makeGetRequest, makePostRequest, makePutRequest } from '@helpers/requests';
import { ADDRESS, ADDRESSES } from '@helpers/api';
import countries from '@data/phone-codes.json';
import { Country } from '@models/country';
import { countryToFlag } from '@helpers/common';
import { useSelector } from 'react-redux';
import { UserState } from '@store/user/types';
import Loader from '@components/common/Loader';

const AddressManager: React.FC<{
	formRef?: any;
	type: 'dialog' | 'card';
	open?: boolean;
	onClose?: () => void;
	onChange: (addressId: string, address: Address) => void;
	address?: string | null;
	displayCardSubmit?: boolean;
	displayCardTitle?: boolean;
}> = ({
	type = 'dialog',
	open = true,
	onClose,
	onChange,
	address: addressId,
	displayCardSubmit = true,
	displayCardTitle = true,
	formRef,
}) => {
	const [openSnackbar] = useSnackbar();
	const [initialCountryCode, setInitialCountryCode] = useState(null);
	const { user } = useSelector(({ user }) => ({ user }));
	const [loading, setLoading] = useState<boolean>(true);
	const [address, setAddress] = useState<Address | null>(null);

	const sortedCountries = countries
		?.sort((a, b) => (a.name > b.name ? 1 : -1))
		?.sort((a) => (a?.code === 'GB' ? -1 : 1))
		?.sort((a) => (a?.code === 'US' ? -1 : 1));

	const handleSubmit = async (values) => {
		try {
			console.log('ADDRESS MANAGER SUBMITTING...');

			const { data: addressData } = !!addressId
				? await makePutRequest(ADDRESS(addressId), {
						...values,
						// not required, remove if not available
						streetTwo: values.streetTwo || undefined,
				  })
				: await makePostRequest(ADDRESSES, {
						...values,
						// not required, remove if not available
						streetTwo: values.streetTwo || undefined,
				  });
			await onChange(addressData?._id, addressData);
			openSnackbar(
				`Your address was successfully ${!!addressId ? 'edited' : 'created'}.`,
				'success'
			);
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(
					error?.errorMessage ??
						`An error occurred attempting to ${
							!!addressId ? 'edit' : 'create'
						} your address.`,
					'error'
				);
		}
	};

	const initialValues: Omit<Address, '_id'> = {
		firstName: address?.firstName ?? user?.firstName ?? '',
		lastName: address?.lastName ?? user?.lastName ?? '',
		country: address?.country ?? (initialCountryCode || ''),
		city: address?.city ?? '',
		county: address?.county ?? '',
		postcode: address?.postcode ?? '',
		streetOne: address?.streetOne ?? '',
		streetTwo: address?.streetTwo ?? '',
	};

	useEffect(() => {
		(async () => {
			try {
				if (!open) return;
				setLoading(true);

				if (addressId) {
					const { data: addressData } = await makeGetRequest(ADDRESS(addressId));
					setAddress(addressData);
				} else {
					const { data: ipData } = await makeGetRequest('https://ipapi.co/json/');
					if (!!ipData?.country_code) setInitialCountryCode(ipData?.country_code);
				}

				setLoading(false);
			} catch (error) {
				if (error !== 'cancelled') {
					setLoading(false);
					throw error;
				}
			}
		})();
	}, [open]);

	const FormContents = ({ touched, errors, values, setFieldValue }) => (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Field
					component={TextField}
					name="firstName"
					label="First name"
					fullWidth
					placeholder="None"
					required
				/>
			</Grid>

			<Grid item xs={6}>
				<Field
					component={TextField}
					name="lastName"
					label="Last name"
					fullWidth
					placeholder="None"
					required
				/>
			</Grid>

			<Grid item xs={12} lg={6}>
				<Field
					required
					name="country"
					component={Autocomplete}
					disabled={!sortedCountries?.length}
					fullWidth
					options={sortedCountries?.map(({ code }) => code)}
					value={values?.country || null}
					onChange={(e, newValue) => {
						setFieldValue('country', newValue || '');
					}}
					getOptionLabel={(id: string) => {
						return sortedCountries?.find(({ code }) => code === id)?.name || '';
					}}
					renderOption={(props, id: string) => {
						const option: Country | undefined = sortedCountries?.find(
							({ code }) => code === id
						);

						return (
							<li {...props}>
								<Box component="span" mr={1}>
									{countryToFlag(option?.code)}
								</Box>
								{option?.name}
							</li>
						);
					}}
					renderInput={(params) => (
						<MuiTextField
							{...params}
							error={touched.country && !!errors.country}
							helperText={errors.country}
							label={!sortedCountries?.length ? 'No countries found' : 'Countries'}
							fullWidth
						/>
					)}
				/>
			</Grid>

			<Grid item xs={12} lg={6}>
				<Field
					required
					component={TextField}
					name="county"
					label="State / Province"
					fullWidth
					placeholder="None"
				/>
			</Grid>

			<Grid item xs={12} lg={6}>
				<Field
					required
					component={TextField}
					name="city"
					label="City"
					fullWidth
					placeholder="None"
				/>
			</Grid>
			<Grid item xs={12} lg={6}>
				<Field
					required
					component={TextField}
					name="postcode"
					label="ZIP Code / Postal Code"
					fullWidth
					placeholder="None"
				/>
			</Grid>

			<Grid item xs={12}>
				<Field
					required
					component={TextField}
					name="streetOne"
					label="Address line one"
					fullWidth
					placeholder="None"
					autoComplete="address-line1"
				/>
			</Grid>
			<Grid item xs={12}>
				<Field
					component={TextField}
					name="streetTwo"
					label="Address line two"
					fullWidth
					placeholder="None"
					autoComplete="address-line2"
				/>
			</Grid>
		</Grid>
	);

	const FormActions = ({ isSubmitting, handleSubmit }) => (
		<Grid container justifyContent="flex-end">
			<Grid item>
				{type === 'dialog' && <Button onClick={onClose}>Cancel</Button>}
				<Button
					onClick={() => handleSubmit()}
					disabled={isSubmitting}
					startIcon={
						isSubmitting ? <CircularProgress color="inherit" size={20} /> : undefined
					}
				>
					{!!addressId ? 'Submit' : 'Create'}
				</Button>
			</Grid>
		</Grid>
	);

	const formikProps = {
		innerRef: formRef,
		onSubmit: handleSubmit,
		initialValues,
		validationSchema: Yup.object().shape({
			firstName: Yup.string().required().label('First name'),
			lastName: Yup.string().required().label('Last name'),
			country: Yup.string().required().label('Country'),
			city: Yup.string().required().label('City'),
			county: Yup.string().required().label('State / Province'),
			postcode: Yup.string().required().label('ZIP Code / Postal Code'),
			streetOne: Yup.string().required().label('Address'),
			streetTwo: Yup.string().nullable().label('Address two'),
		}),
	};

	if (type === 'card') {
		return loading ? (
			<Card>
				<CardHeader>{!!addressId ? 'Editing' : 'Creating'} an address</CardHeader>
				<CardContent>
					<Box height="300px">
						<Loader />
					</Box>
				</CardContent>
			</Card>
		) : (
			<Formik {...formikProps}>
				{(formikProps) => (
					<Card>
						<CardContent>
							{displayCardTitle && (
								<>
									<Typography variant="h4" component="h3">
										{!!addressId ? 'Editing' : 'Creating'} an address
									</Typography>
									<Box mt={3} />
								</>
							)}

							<FormContents {...formikProps} />
						</CardContent>

						{displayCardSubmit && (
							<CardActions>
								<FormActions {...formikProps} />
							</CardActions>
						)}
					</Card>
				)}
			</Formik>
		);
	}

	return (
		<Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
			{loading ? (
				<>
					<DialogTitle>{!!addressId ? 'Editing' : 'Creating'} an address</DialogTitle>
					<DialogContent>
						<Box height="300px">
							<Loader />
						</Box>
					</DialogContent>
				</>
			) : (
				<Formik {...formikProps}>
					{(formikProps) => {
						return (
							<>
								<DialogTitle>
									{!!addressId ? 'Editing' : 'Creating'} an address
								</DialogTitle>
								<DialogContent>
									<FormContents {...formikProps} />
								</DialogContent>
								<DialogActions>
									<FormActions {...formikProps} />
								</DialogActions>
							</>
						);
					}}
				</Formik>
			)}
		</Dialog>
	);
};

export default AddressManager;
