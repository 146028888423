import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikValues, useFormikContext } from 'formik';
import useDidUpdateEffect from '@helpers/hooks/useDidUpdateEffect';
import {
	Box,
	Button,
	CircularProgress,
	Fade,
	FormControlLabel,
	Grid,
	List,
	ListItem,
	ListItemText,
	Paper,
	Switch,
	Typography,
	useMediaQuery,
	Skeleton,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import styles from './styles';
import { makeGetRequest } from '@helpers/requests';
import { ADDRESSES, CHECKOUT_BRAINTREE_TOKEN } from '@helpers/api';
import { useSnackbar } from '@components/common/Snackbar';
import AddressSelect from '@components/common/AddressSelect';
import { CheckoutPaymentValidation } from '@views/Checkout';
import Loader from '@components/common/Loader';
import DropIn from 'braintree-web-drop-in-react';
import { getShippingCost, getTotalCost } from '@helpers/checkout';
import { useSelector } from 'react-redux';
import { UserState } from '@store/user/types';
import { formatAddress } from '@helpers/common';
import { Addresses } from '@models/address';

const Payment: React.FC<{
	onSubmit: (values: FormikValues, actions: any) => void;
	onBack: () => void;
}> = ({ onSubmit, onBack }) => {
	const { values: checkoutValues, setFieldValue: onChangeFieldValue }: any = useFormikContext();
	const [openSnackbar] = useSnackbar();
	const braintree = useRef(null);
	const [show, setShow] = useState(true);
	const theme = useTheme();
	const [addresses, setAddresses] = useState<Addresses>([]);
	const [token, setToken] = useState(null);
	const [submitTimeout, setSubmitTimeout] = useState<any>(null);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const initialValues: {
		billingAddressId: string | null;
	} = {
		billingAddressId: null,
	};
	const shippingCost = getShippingCost(checkoutValues.shippingCode);
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));
	const { user } = useSelector(({ user }) => ({ user }));

	console.log({ braintree });

	const handleSubmit = async (values, actions) => {
		try {
			setShow(false);
			setSubmitTimeout(
				setTimeout(() => {
					setSubmitting(true);
				}, 250)
			);
			await onSubmit({ braintree, ...values }, actions);
			actions.setSubmitting(false);
		} catch (error) {
			setSubmitting(false);
			setSubmitTimeout(
				setTimeout(() => {
					setShow(true);
				}, 250)
			);
			actions.setSubmitting(false);
		}
	};

	const handleSetAddress = async () => {
		try {
			const { data: token } = await makeGetRequest(CHECKOUT_BRAINTREE_TOKEN);
			const { data: adressesData } = await makeGetRequest(ADDRESSES);
			setAddresses(adressesData);
			setToken(token);
		} catch (error) {
			console.log({ error });

			error !== 'cancelled' &&
				openSnackbar(
					error?.errorMessage ?? 'An error occurred loading Braintree.',
					'error'
				);
		}
	};

	useEffect(() => {
		handleSetAddress();

		return () => {
			clearTimeout(submitTimeout);
		};
	}, []);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={CheckoutPaymentValidation}
			onSubmit={handleSubmit}
		>
			{({ handleSubmit, setFieldValue, values, touched, errors, isSubmitting }) => {
				const [sameAsShipping, setSameAsShipping] = useState<boolean>(true);
				const [loadingBraintree, setLoadingBraintree] = useState<boolean>(true);
				console.log({ values });
				const shippingAddress = addresses?.find(
					({ _id }) => _id === checkoutValues.shippingAddressId
				);
				const billingAddress = addresses?.find(
					({ _id }) => _id === values.billingAddressId
				);

				useEffect(() => {
					setFieldValue(
						'billingAddressId',
						sameAsShipping ? checkoutValues?.shippingAddressId : null
					);
				}, [sameAsShipping]);

				return (
					<Grid
						css={styles(theme)}
						container
						spacing={4}
						direction={desktop ? 'row' : 'column'}
						justifyContent="space-between"
						wrap={desktop ? 'wrap' : 'nowrap'}
					>
						<Grid item xs lg={12}>
							<Fade unmountOnExit in={submitting} timeout={{ enter: 500, exit: 250 }}>
								<Loader />
							</Fade>
							<Fade unmountOnExit in={show} timeout={{ enter: 500, exit: 250 }}>
								<div>
									<Typography gutterBottom variant="h1">
										Place your order
									</Typography>
									<Typography>
										Once you've placed a successful order, we will send an order
										receipt to <b>{user?.email}</b>.
									</Typography>

									<Box mt={4} />

									<FormControlLabel
										label="Use my shipping address for billing details too"
										control={
											<Switch
												checked={sameAsShipping}
												onChange={() => setSameAsShipping(!sameAsShipping)}
												inputProps={{
													'aria-label':
														'Use the shipping address for billing details',
												}}
											/>
										}
									/>
								</div>
							</Fade>

							<Fade
								unmountOnExit
								in={show && !sameAsShipping}
								timeout={{ enter: 500, exit: 250 }}
							>
								<div>
									<Box mt={4} />
									<Typography component="h2" variant="h3">
										Your billing address
									</Typography>
									<Box mt={3} />
									<AddressSelect
										address={values.billingAddressId}
										onChange={(newAddressId) => {
											handleSetAddress();
											setFieldValue('billingAddressId', newAddressId);
										}}
										error={touched.billingAddressId && errors.billingAddressId}
									/>
								</div>
							</Fade>

							<Fade in={show} timeout={{ enter: 500, exit: 250 }}>
								<div>
									<Box mt={4} />
									<Typography component="h2" variant="h3">
										Payment details
									</Typography>
									<div style={{ position: 'relative' }}>
										{loadingBraintree ? (
											<>
												<Box mt={2} />
												<Skeleton
													variant="rectangular"
													width="100%"
													height={275}
												/>
											</>
										) : null}
										{!token ? null : (
											<Fade in={!loadingBraintree}>
												<div>
													<Box mt={2} />
													<div
														className={`${
															loadingBraintree
																? 'braintree-dropin-loading'
																: ''
														}`}
													>
														<DropIn
															options={{
																vaultManager: true,
																authorization: token,
																paypal: {
																	flow: 'vault',
																},
																onError: (error) => {
																	console.log(
																		'onError',
																		error,
																		error()
																	);
																	openSnackbar(
																		error()?.error?.message
																	);
																	setShow(true);
																},
																// applePay: {
																//     displayName: 'My Store',
																//     paymentRequest: {
																//         total: {
																//             label: 'My Store',
																//             amount: total.toFixed(2),
																//         },
																//         requiredBillingContactFields: ['postalAddress'],
																//     },
																// },
																// googlePay: {
																//     merchantId: '09559114429702614958', // Omit this line in sandbox; insert your Google merchant ID in production
																//     transactionInfo: {
																//         currencyCode: 'GBP',
																//         totalPriceStatus: 'FINAL',
																//         totalPrice: total.toFixed(2), // Your amount
																//     },
																// },
																// venmo: {
																//     allowNewBrowserTab: false,
																// },
																paypalCredit: {
																	flow: 'checkout',
																	amount: (49.99).toFixed(2),
																	currency: 'USD',
																},
															}}
															onInstance={() =>
																setLoadingBraintree(false)
															}
															ref={braintree}
														/>
													</div>
												</div>
											</Fade>
										)}
									</div>

									<Box mt={2} />

									<Paper>
										<List>
											<Fade
												unmountOnExit
												in={!!shippingAddress}
												timeout={{ enter: 500, exit: 250 }}
											>
												<ListItem>
													<ListItemText
														primary="Shipping address"
														secondary={formatAddress(shippingAddress)}
													/>
												</ListItem>
											</Fade>
											<Fade
												unmountOnExit
												in={!!billingAddress}
												timeout={{ enter: 500, exit: 250 }}
											>
												<ListItem>
													<ListItemText
														primary="Billing address"
														secondary={formatAddress(billingAddress)}
													/>
												</ListItem>
											</Fade>
											<ListItem>
												<ListItemText
													primary="Shipping cost"
													secondary={
														shippingCost
															? `£${shippingCost} GBP`
															: 'Free'
													}
												/>
											</ListItem>
											<ListItem>
												<ListItemText
													primary="Total"
													secondary={`£${getTotalCost(
														checkoutValues.shippingCode
													)} GBP`}
													secondaryTypographyProps={{
														style: { fontWeight: 700 },
													}}
												/>
											</ListItem>
										</List>
									</Paper>
								</div>
							</Fade>
						</Grid>
						<Grid item lg={12}>
							<Fade in={show} timeout={{ enter: 500, exit: 250 }}>
								<Grid container justifyContent="space-between">
									<Grid item>
										<Button color="secondary" onClick={onBack}>
											Back
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											onClick={() => handleSubmit()}
											disabled={isSubmitting || loadingBraintree}
											startIcon={
												isSubmitting || loadingBraintree ? (
													<CircularProgress color="inherit" size={20} />
												) : undefined
											}
										>
											Place order
										</Button>
									</Grid>
								</Grid>
							</Fade>
						</Grid>
					</Grid>
				);
			}}
		</Formik>
	);
};

export default Payment;
