import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardContent,
	Fade,
	Grid,
	Grow,
	Radio,
	Typography,
	useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { Formik, FormikValues, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/styles';
import styles from './styles';
import { CheckoutCardColourValidation } from '@views/Checkout';
import useDidUpdateEffect from '@helpers/hooks/useDidUpdateEffect';
import { Alert } from '@mui/material';
import { CheckoutCardColour } from '@models/checkout';
import PaletteCard from '@components/Landing/common/PaletteCard';

const Colour: React.FC<{
	onSubmit: (values: FormikValues) => void;
	onBack: () => void;
}> = ({ onSubmit, onBack }) => {
	const { values, setFieldValue: onChangeFieldValue }: any = useFormikContext();
	const [show, setShow] = useState(true);
	const theme = useTheme();
	const initialValues: { colour: CheckoutCardColour } = {
		colour: values?.card?.colour ?? null,
	};
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));

	const handleSubmit = (values) => {
		setShow(false);
		setTimeout(() => onSubmit(values), 500);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				colour: CheckoutCardColourValidation,
			})}
			onSubmit={handleSubmit}
		>
			{({ handleSubmit, setFieldValue, values, touched, errors }) => {
				useDidUpdateEffect(() => {
					onChangeFieldValue('card.colour', values.colour);
				}, [values]);

				return (
					<Grid
						css={styles(theme)}
						container
						spacing={4}
						direction={desktop ? 'row' : 'column'}
						justifyContent="space-between"
						wrap={desktop ? 'wrap' : 'nowrap'}
					>
						<Grid item xs lg={12}>
							<Fade in={show} timeout={{ enter: 500, exit: 250 }}>
								<div>
									<Typography variant="h1">Choose your finish</Typography>
									<Box mt={1} />
									<Typography>
										All of our cards are designed to the highest standard in
										stainless steel.
									</Typography>
								</div>
							</Fade>
							<Box mt={4} />
							<Grid container spacing={3}>
								<Grid item xs={4} sm={3} md={2} lg={4}>
									<Grow in={show} timeout={{ enter: 500, exit: 250 }}>
										<div>
											<PaletteCard
												onSelected={() => setFieldValue('colour', 'black')}
												colour="black"
												selected={values.colour}
											/>
										</div>
									</Grow>
								</Grid>
								<Grid item xs={4} sm={3} md={2} lg={4}>
									<Grow in={show} timeout={{ enter: 1000, exit: 500 }}>
										<div>
											<PaletteCard
												onSelected={() => setFieldValue('colour', 'silver')}
												colour="silver"
												selected={values.colour}
											/>
										</div>
									</Grow>
								</Grid>
								<Grid item xs={4} sm={3} md={2} lg={4}>
									<Grow in={show} timeout={{ enter: 1000, exit: 500 }}>
										<div>
											<PaletteCard
												onSelected={() => setFieldValue('colour', 'gold')}
												colour="gold"
												selected={values.colour}
											/>
										</div>
									</Grow>
								</Grid>
							</Grid>

							{touched.colour && errors.colour && (
								<>
									<Fade in={show} timeout={{ enter: 500, exit: 250 }}>
										<div>
											<Box mt={2} />
											<Alert severity="error">{errors.colour}</Alert>
										</div>
									</Fade>
								</>
							)}
						</Grid>
						<Grid item lg={12}>
							<Fade in={show} timeout={{ enter: 500, exit: 250 }}>
								<Grid container justifyContent="space-between">
									<Grid item>
										<Button color="secondary" onClick={onBack}>
											Back
										</Button>
									</Grid>
									<Grid item>
										<Button variant="contained" onClick={() => handleSubmit()}>
											Continue
										</Button>
									</Grid>
								</Grid>
							</Fade>
						</Grid>
					</Grid>
				);
			}}
		</Formik>
	);
};

export default Colour;
