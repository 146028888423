import React, { useEffect, useState } from 'react';
import { Address, Addresses } from '@models/address';
import {
	Alert,
	Box,
	Button,
	Grid,
	IconButton,
	TextField,
	Autocomplete,
	createFilterOptions,
} from '@mui/material';
import { useSnackbar } from '@components/common/Snackbar';
import { makeGetRequest } from '@helpers/requests';
import { ADDRESSES } from '@helpers/api';
import AddressManager from '@components/common/AddressManager';
import { Add, Edit } from '@mui/icons-material';
import Loader from '@components/common/Loader';
import { useTheme } from '@mui/material';
import usePrevious from '@helpers/hooks/usePrevious';
import { formatAddress } from '@helpers/common';

const filter = createFilterOptions<string>();

const AddressSelect: React.FC<{
	address: string | null;
	onChange: (newAddressId: string) => void;
	error?: string | false;
	autoSelect?: boolean;
	[x: string]: any;
}> = ({ autoSelect, onChange, address, error, emptyCreateType = 'button' }) => {
	const theme = useTheme();
	const [openSnackbar] = useSnackbar();
	const [addresses, setAddresses] = useState<Addresses>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [creating, setCreating] = useState<boolean>(false);
	const [editingAddressId, setEditingAddressId] = useState<string | null>(null);
	const prevAddresses = usePrevious(addresses);

	const handleSetAddress = async () => {
		try {
			setLoading(true);
			const { data: adressesData } = await makeGetRequest(ADDRESSES);
			setAddresses(adressesData);
			setLoading(false);
		} catch (error) {
			if (error !== 'cancelled') {
				openSnackbar(
					error?.errorMessage ??
						'An error occurred attempting to retrieve your addresses.',
					'error'
				);
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		handleSetAddress();
	}, []);

	useEffect(() => {
		// initial run now addresses exist
		if (!address && autoSelect && !!prevAddresses && !!addresses) {
			onChange(addresses?.[0]?._id);
		}
	}, [addresses]);

	if (loading) return <Loader />;

	return (
		<div>
			{!addresses?.length ? (
				emptyCreateType === 'button' ? (
					<Button
						startIcon={<Add />}
						variant="contained"
						color="secondary"
						onClick={() => setCreating(true)}
					>
						Create an address
					</Button>
				) : null
			) : (
				<Autocomplete
					classes={{
						popperDisablePortal: 'address-portal',
						option: 'address-item',
					}}
					value={address || null}
					onChange={(event, newValue: any) => {
						if (newValue?.create) {
							setCreating(true);
							return;
						}

						onChange(newValue || null);
					}}
					disablePortal
					filterOptions={(options: string[], params) => {
						const filtered = filter(options, params);

						return [
							...filtered,
							{
								create: true,
								title: 'Create new address',
							},
						];
					}}
					selectOnFocus
					clearOnBlur
					handleHomeEndKeys
					options={addresses.map(({ _id }) => _id)}
					getOptionLabel={(option: any) => {
						const address = addresses.find(({ _id }) => _id === option);

						return !!address ? formatAddress(address) : '';
					}}
					loading={creating}
					renderOption={(props, option: any) => {
						const address = addresses.find(({ _id }) => _id === option);

						return !!address ? (
							<li {...props}>
								<span className="address-item-text">{formatAddress(address)}</span>
								<IconButton
									size="small"
									className="address-item-edit-icon"
									onClick={() => setEditingAddressId(address._id)}
								>
									<Edit />
								</IconButton>
							</li>
						) : option?.create ? (
							<li {...props}>
								<Add />
								<Box ml={1} />
								{option?.title}
							</li>
						) : null;
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Addresses"
							error={!!error}
							helperText={error}
						/>
					)}
					placeholder="Select an address"
				/>
			)}

			<AddressManager
				type={!addresses?.length ? 'card' : 'dialog'}
				open={
					(!addresses?.length && emptyCreateType === 'card') ||
					creating ||
					!!editingAddressId
				}
				onClose={() => {
					setCreating(false);
					setEditingAddressId(null);
				}}
				onChange={async (addressId) => {
					await handleSetAddress();
					setCreating(false);
					setEditingAddressId(null);
					onChange(addressId);
				}}
				address={editingAddressId}
			/>

			{!addresses?.length && emptyCreateType === 'card' && !!error && (
				<>
					<Box mt={2} />
					<Alert severity="error">{error}</Alert>
				</>
			)}
		</div>
	);
};

export default AddressSelect;
