import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { amber, grey, yellow } from '@mui/material/colors';

export default (theme: Theme) => css`
	&.palette-card {
		outline: 0;
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;
		border-radius: ${theme.spacing(1)};

		.palette-card-content {
			padding: ${theme.spacing(0, 1.5)};
			${theme.breakpoints.down('sm')} {
				padding: ${theme.spacing(0, 0.7)};
				padding-right: 0;
			}
		}

		${theme.breakpoints.down('sm')} {
			border-radius: ${theme.spacing(1)};
		}

		&,
		.palette-card-content-radio {
			color: ${theme.palette.common.white};
			padding: 0;

			&.Mui-checked {
				color: ${theme.palette.primary.main};
			}
		}

		&::before {
			padding-top: 100%;
			content: '';
			display: block;
			float: left;
		}

		&.palette-card-black {
			background-color: ${grey['900']};
		}

		&.palette-card-silver {
			background-color: ${grey['400']};
		}

		&.palette-card-gold {
			background-color: ${amber['500']};
		}

		.palette-card-content {
			&,
			.palette-card-content-grid {
				height: 100%;
				padding-right: 3px;
			}
		}

		.palette-card-content-text {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 1rem;
			letter-spacing: 0.12em;
			color: ${grey['100']};

			${theme.breakpoints.down('sm')} {
				font-size: 0.8rem;
			}
		}
	}
`;
