import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikValues, useFormikContext } from 'formik';
import {
	Box,
	Button,
	CircularProgress,
	Fade,
	Grid,
	Typography,
	useMediaQuery,
	Card,
	CardContent,
	Radio,
	CardActionArea,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import styles from './styles';
import { useSnackbar } from '@components/common/Snackbar';
import AddressSelect from '@components/common/AddressSelect';
import { CheckoutShippingValidation } from '@views/Checkout';
import { makeGetRequest } from '@helpers/requests';
import { ADDRESSES } from '@helpers/api';
import { Addresses } from '@models/address';
import AddressManager from '@components/common/AddressManager';
import Loader from '@components/common/Loader';
import { getAvailableShippingCodes } from '@helpers/checkout';

const Shipping: React.FC<{ onSubmit: (values: FormikValues) => void; onBack: () => void }> = ({
	onSubmit,
	onBack,
}) => {
	const { values: checkoutValues, setFieldValue: onChangeFieldValue }: any = useFormikContext();
	const [show, setShow] = useState(true);
	const [submitTimeout, setSubmitTimeout] = useState<any>(null);
	const theme = useTheme();
	const createAddressFormRef = useRef<any>(null);
	const initialValues: {
		shippingAddressId: string | null;
		shippingCode: string | null;
	} = {
		shippingAddressId: checkoutValues?.shippingAddressId,
		shippingCode: checkoutValues?.shippingCode,
	};
	const [loading, setLoading] = useState<boolean>(true);
	const [addresses, setAddresses] = useState<Addresses | null>(null);
	const [openSnackbar] = useSnackbar();
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));

	const handleSubmit = (values, actions) => {
		actions.setSubmitting(false);
		setShow(false);
		setSubmitTimeout(setTimeout(() => onSubmit(values), 500));
	};

	const handleSetAddresses = async () => {
		try {
			const { data: adressesData } = await makeGetRequest(ADDRESSES);
			setAddresses(adressesData);
		} catch (error) {
			if (error !== 'cancelled') {
				openSnackbar(
					error?.errorMessage ??
						'An error occurred attempting to retrieve your addresses.'
				);
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		(async () => {
			setLoading(true);
			await handleSetAddresses();
			setLoading(false);
		})();

		return () => {
			clearTimeout(submitTimeout);
		};
	}, []);

	if (loading) return <Loader />;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={CheckoutShippingValidation}
			onSubmit={handleSubmit}
		>
			{({
				handleSubmit,
				setFieldValue,
				values,
				touched,
				errors,
				setSubmitting,
				isSubmitting,
			}) => {
				const shippingAddress = addresses?.find(
					({ _id }) => _id === values.shippingAddressId
				);
				const availableShippingCodes = getAvailableShippingCodes(shippingAddress);

				const ShippingCard: React.FC<{ id: string; label: string; price: number }> = ({
					id,
					label,
					price,
				}) => (
					<Card style={{ height: '100%' }}>
						<CardActionArea
							style={{ height: '100%' }}
							onClick={() => setFieldValue('shippingCode', id)}
						>
							<CardContent>
								<Grid container alignItems="center">
									<Grid item xs>
										<Typography variant="caption">{label}</Typography>
										<Typography>
											{price === 0 ? 'Free' : `£${price}`}
										</Typography>
									</Grid>
									<Grid item xs="auto">
										<Radio checked={values.shippingCode === id} />
									</Grid>
								</Grid>
							</CardContent>
						</CardActionArea>
					</Card>
				);

				useEffect(() => {
					(async () => {
						// update addresses array when shippingAddressId changes and doesn't exist
						if (
							!!values.shippingAddressId &&
							addresses?.every(({ _id }) => _id !== values.shippingAddressId)
						) {
							// if no addresses exist, this was assigned from a card creation form, so hide the whole
							// form until addresses exist so the form swaps to the select dropdown view
							if (!addresses?.length) {
								setShow(false);
								await handleSetAddresses();
								// must wrap this in a timeout as we can't assume the above request will take longer
								// than 250ms (length of the exit animation)
								setSubmitTimeout(
									setTimeout(() => {
										setShow(true);
									}, 250)
								);
								// as this was created from an internal card form, we must manually turn submitting off
								setSubmitting(false);
								return;
							}

							await handleSetAddresses();
						}
					})();
				}, [values.shippingAddressId]);

				useEffect(() => {
					if (!!shippingAddress) {
						// current shipping code doesn't exist in the new address
						if (!availableShippingCodes?.some(({ id }) => id === values.shippingCode)) {
							setFieldValue('shippingCode', availableShippingCodes?.[0]?.id ?? null);
						}
					}
				}, [shippingAddress]);

				const handlePreSubmit = async () => {
					try {
						if (!addresses?.length) {
							// triggers an internal formik submit to create the address - this then triggers the
							// AddressManager onChange which triggers the current form submission
							setSubmitting(true);

							console.log(3)
							await createAddressFormRef?.current?.handleSubmit?.();
						} else {
							console.log(2)
							handleSubmit();
						}
					} catch (error) {
						console.log(1)
						setSubmitting(false);
					}
				};

				return (
					<Grid
						css={styles(theme)}
						container
						spacing={4}
						direction={desktop ? 'row' : 'column'}
						justifyContent="space-between"
						wrap={desktop ? 'wrap' : 'nowrap'}
					>
						<Grid item xs lg={12}>
							<Fade unmountOnExit in={show} timeout={{ enter: 500, exit: 250 }}>
								<div>
									<Typography component="h1" variant="h2">
										Your shipping address
									</Typography>
									<Box mt={4} />

									<Fade
										unmountOnExit
										// in={!addresses?.length && !values.shippingAddressId}
										in={!addresses?.length}
										timeout={{ enter: 500, exit: 250 }}
									>
										<div>
											<AddressManager
												type="card"
												onChange={(addressId) => {
													setFieldValue('shippingAddressId', addressId);
												}}
												formRef={createAddressFormRef}
												displayCardSubmit={false}
												displayCardTitle={false}
											/>
										</div>
									</Fade>

									<Fade unmountOnExit in={!!addresses?.length}>
										<div>
											<AddressSelect
												autoSelect
												onChange={(addressId) =>
													setFieldValue('shippingAddressId', addressId)
												}
												address={values.shippingAddressId}
												error={
													touched.shippingAddressId &&
													errors.shippingAddressId
												}
											/>
										</div>
									</Fade>

									<Fade unmountOnExit in={!!availableShippingCodes}>
										<div>
											<Box mt={3} />
											<Typography variant="h3">Shipping options</Typography>
											<Box mt={2} />
											<Grid container spacing={2}>
												{availableShippingCodes?.map(
													({ name, id, price }) => (
														<Grid key={id} item xs={12} md={6}>
															<ShippingCard
																label={name}
																id={id}
																price={price}
															/>
														</Grid>
													)
												)}
											</Grid>
										</div>
									</Fade>
								</div>
							</Fade>
						</Grid>
						<Grid item lg={12}>
							<Fade in={show} timeout={{ enter: 500, exit: 250 }}>
								<Grid container justifyContent="space-between">
									<Grid item>
										<Button color="secondary" onClick={onBack}>
											Back
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											onClick={handlePreSubmit}
											// disabled={isSubmitting}
											startIcon={
												isSubmitting ? (
													<CircularProgress color="inherit" size={20} />
												) : undefined
											}
										>
											{!addresses?.length ? 'Create' : 'Continue'}
										</Button>
									</Grid>
								</Grid>
							</Fade>
						</Grid>
					</Grid>
				);
			}}
		</Formik>
	);
};

export default Shipping;
