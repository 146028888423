import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { amber, grey } from '@mui/material/colors';
import { transparentize } from 'polished';

const logoWidth = 51.1 * 5;
const cardWidth = 86 * 5;
const cardHeight = 54 * 5;
const cardGutter = 7.6 * 5;
const cardLines = 60 * 5;
const cardLineHeight = 3 * 5;

export default (theme: Theme) => css`
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	left: 50%;
	background: ${theme.palette.primary.main};
	height: 100%;
	z-index: -1;
	min-height: 600px;
	display: flex;
	align-items: center;

	&.preview-mobile {
		left: 0;
		height: 100%;
		bottom: 0;
		top: auto;
		position: static;
		min-height: 400px;
	}

	.card {
		//width: 400px;
		//height: 250px;
		border-radius: 20px;
		position: relative;
		background-color: ${transparentize(0.8, grey['900'])};
		// width: ${cardWidth}px;
		// height: ${cardHeight}px;
		transition: ${theme.transitions.create(['background-color', 'box-shadow'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		})};

		width: 100%;
		//min-width: 400px;
		// card aspect ratio (100 / 1.586)
		padding-top: 63.051702396%;

		${theme.breakpoints.up('lg')} {
			// offset the header height
			margin-top: 112px;
		}

		&.card-black,
		&.card-silver,
		&.card-gold {
			box-shadow: ${theme.shadows[12]};
		}

		&.card-black {
			background-color: ${grey['900']};
		}

		&.card-silver {
			background-color: ${grey['400']};
		}

		&.card-gold {
			background-color: ${amber['500']};
		}

		.card-logo {
			position: absolute;
			top: ${cardGutter}px;
			left: ${cardGutter}px;
			width: 85%;
			transition: ${theme.transitions.create('fill', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			})};

			&,
			* {
				fill: ${theme.palette.common.white};
			}
		}

		&.card-silver,
		&.card-gold {
			.card-logo {
				&,
				* {
					fill: ${theme.palette.common.black};
				}
			}

			.card-lines {
				.card-line {
					.card-first-line,
					.card-second-line {
						background-color: ${transparentize(0.85, theme.palette.common.black)};

						&.card-first-line-filled,
						&.card-second-line-filled {
							color: ${theme.palette.common.black};
						}
					}
				}
			}

			.card-nfc {
				.card-nfc-line {
					background-color: ${theme.palette.common.black};
				}
			}
		}

		.card-nfc {
			position: absolute;
			right: ${cardGutter}px;
			top: 50%;
			transform: translateY(-50%);

			.card-nfc-line {
				width: 3px;
				background-color: ${theme.palette.common.white};
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				transition: ${theme.transitions.create('background-color', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen,
				})};

				&:nth-of-type(1) {
					height: 9px;
					right: 30px;
				}

				&:nth-of-type(2) {
					height: 22px;
					right: 15px;
				}

				&:nth-of-type(3) {
					height: 35px;
					right: 0;
				}
			}
		}

		.card-lines {
			position: absolute;
			bottom: ${cardGutter}px;
			left: ${cardGutter}px;

			.card-line {
				position: relative;
				width: ${cardLines}px;
				height: ${cardLineHeight}px;
				transition: ${theme.transitions.create('top', {
					duration: theme.transitions.duration.shortest,
				})};
				top: 0;

				&.card-line-single {
					top: ${cardLineHeight + cardLineHeight / 2}px;
				}

				.card-first-line,
				.card-second-line {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: ${transparentize(0.85, theme.palette.common.white)};
					transition: ${theme.transitions.create('background-color', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.enteringScreen,
					})};

					p {
						text-transform: uppercase;
						letter-spacing: 0.14em;
						font-weight: 700;
						line-height: 1.25;
						margin-top: -4px;
					}

					&.card-first-line-filled,
					&.card-second-line-filled {
						height: auto;
						background-color: transparent;
						color: ${theme.palette.common.white};
					}
				}
			}
		}
	}
`;
