import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { amber, grey, yellow } from '@mui/material/colors';

export default (theme: Theme) => css`
	height: 100%;

	.palette-card {
		outline: 0;
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;

		&,
		.palette-card-content-radio {
			color: ${theme.palette.common.white};
		}

		&::before {
			padding-top: 100%;
			content: '';
			display: block;
			float: left;
		}

		&.palette-card-black {
			background-color: ${grey['900']};
		}

		&.palette-card-silver {
			background-color: ${grey['400']};
		}

		&.palette-card-gold {
			background-color: ${amber['500']};
		}

		.palette-card-content {
			&,
			.palette-card-content-grid {
				height: 100%;
			}
		}

		.palette-card-content-text {
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 0.12em;

			${theme.breakpoints.down('md')} {
				font-size: 0.9rem;
			}
		}
	}
`;
