import { Button, Checkbox, Fade, FormControlLabel, Link, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Formik, FormikValues } from 'formik';
import { useTheme } from '@mui/styles';
import styles from './styles';

const Disclaimer: React.FC<{
	onSubmit: (values: FormikValues) => void;
}> = ({ onSubmit }) => {
	const [show, setShow] = useState(true);
	const [checked, setChecked] = useState(false);
	const theme = useTheme();
	const initialValues: { accept: boolean } = {
		accept: false,
	};

	const handleSubmit = () => {
		setShow(false);
		setTimeout(() => onSubmit(), 500);
	};

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit}>
			{() => {
				return (
					<Fade in={show} timeout={{ enter: 500, exit: 250 }}>
						<Stack
							className="disclaimer"
							justifyContent="flex-start"
							alignItems="flex-start"
							css={styles(theme)}
						>
							<Typography className="disclaimer-header" variant="h1">
								Disclaimer
							</Typography>
							<Typography className="disclaimer-body" variant="body1">
								Wave Card is in <strong>Alpha</strong>, meaning that it's in the
								early production phase, so software may have bugs, lack of features
								and the physical product may be different to the final release item.
								If you feel passionate about Wave Card please continue buying one of
								our cards at a discounted price and provide feedback on the platform
								so we can prioritise the features you want to see. Your card will
								never become obsolete and you will always have access to the final
								platform.
							</Typography>
							<Link
								href="https://elementsoftworks.co.uk/terms-and-conditions"
								target="_blank"
								className="disclaimer-link"
								underline="none"
							>
								Read full{' '}
								<span style={{ textDecoration: 'underline' }}>
									terms & conditions
								</span>
							</Link>
							<FormControlLabel
								control={<Checkbox onClick={() => setChecked(!checked)} />}
								label="I agree to the terms and conditions"
								labelPlacement="start"
								className="disclaimer-checkbox"
							/>
							<Button
								className="disclaimer-btn"
								disabled={!checked}
								variant="contained"
								onClick={() => handleSubmit()}
							>
								Continue
							</Button>
						</Stack>
					</Fade>
				);
			}}
		</Formik>
	);
};

export default Disclaimer;
