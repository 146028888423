import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	height: 100%;
	width: 80%;
	font-size: 1rem;
	margin: auto 0;

	.disclaimer-header,
	.disclaimer-body {
		margin-bottom: ${theme.spacing(3)};
	}

	.disclaimer-link {
		margin-bottom: ${theme.spacing(2)};
	}

	.disclaimer-checkbox {
		margin: ${theme.spacing(0)};
		margin-bottom: ${theme.spacing(1)};
	}

	.disclaimer-btn {
		align-self: flex-end;
	}
`;
