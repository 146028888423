import React, { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import {
	Box,
	Button,
	CircularProgress,
	Fade,
	Grid,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import styles from './styles';
import { makeGetRequest } from '@helpers/requests';
import { ADDRESSES } from '@helpers/api';
import { useSnackbar } from '@components/common/Snackbar';
import { cardCost, getShippingCode, getShippingCost, getTotalCost } from '@helpers/checkout';
import { formatAddress, getCardColour } from '@helpers/common';
import { Addresses } from '@models/address';

const Review: React.FC<{
	onContinue: () => void;
	onBack: () => void;
}> = ({ onContinue, onBack }) => {
	const { values: checkoutValues, setFieldValue: onChangeFieldValue }: any = useFormikContext();
	const braintree = useRef(null);
	const [show, setShow] = useState(true);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const theme = useTheme();
	const [submitTimeout, setSubmitTimeout] = useState<any>(null);
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));
	const shippingCode = getShippingCode(checkoutValues.shippingCode);
	const shippingCost = getShippingCost(checkoutValues.shippingCode);
	const [loading, setLoading] = useState(true);
	const [addresses, setAddresses] = useState<Addresses>([]);
	const [openSnackbar] = useSnackbar();
	const shippingAddress = addresses?.find(({ _id }) => _id === checkoutValues.shippingAddressId);

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);
				const { data: adressesData } = await makeGetRequest(ADDRESSES);
				setAddresses(adressesData);
				setLoading(false);
			} catch (error) {
				if (error !== 'cancelled') {
					openSnackbar(
						error?.errorMessage ??
							'An error occurred attempting to retrieve your addresses.'
					);
					setLoading(false);
				}
			}
		})();

		return () => {
			clearTimeout(submitTimeout);
		};
	}, []);

	const handleSubmit = () => {
		setSubmitting(true);
		setShow(false);
		setSubmitTimeout(setTimeout(() => onContinue(), 500));
	};

	return (
		<Grid
			css={styles(theme)}
			container
			spacing={4}
			direction={desktop ? 'row' : 'column'}
			justifyContent="space-between"
			wrap={desktop ? 'wrap' : 'nowrap'}
		>
			<Grid item xs lg={12}>
				<Fade in={show} timeout={{ enter: 500, exit: 250 }}>
					<Typography variant="h1">Review your order</Typography>
				</Fade>
				<Box mt={4} />

				<Fade in={show} timeout={{ enter: 500, exit: 250 }}>
					<Paper elevation={1}>
						<List>
							<ListItem>
								<ListItemText
									primary="Card color"
									secondary={getCardColour(checkoutValues.card.colour)}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Card line one"
									secondary={checkoutValues.card.lineOne.toUpperCase()}
								/>
							</ListItem>
							{!!checkoutValues.card.lineTwo && (
								<ListItem>
									<ListItemText
										primary="Card line one"
										secondary={checkoutValues.card.lineTwo.toUpperCase()}
									/>
								</ListItem>
							)}
							<ListItem>
								<ListItemText
									primary="Shipping address"
									secondary={formatAddress(shippingAddress)}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Shipping type"
									secondary={shippingCode?.name ?? ''}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Shipping cost"
									secondary={shippingCost ? `£${shippingCost} GBP` : 'Free'}
								/>
							</ListItem>
							<ListItem>
								<ListItemText primary="Subtotal" secondary={`£${cardCost} GBP`} />
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Total"
									secondary={`£${getTotalCost(checkoutValues.shippingCode)} GBP`}
									secondaryTypographyProps={{
										style: { fontWeight: 700 },
									}}
								/>
							</ListItem>
						</List>
					</Paper>
				</Fade>
			</Grid>
			<Grid item lg={12}>
				<Fade in={show} timeout={{ enter: 500, exit: 250 }}>
					<Grid container justifyContent="space-between">
						<Grid item>
							<Button color="secondary" onClick={onBack}>
								Back
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								onClick={handleSubmit}
								disabled={submitting}
								startIcon={
									submitting ? (
										<CircularProgress color="inherit" size={20} />
									) : undefined
								}
							>
								Continue
							</Button>
						</Grid>
					</Grid>
				</Fade>
			</Grid>
		</Grid>
	);
};

export default Review;
