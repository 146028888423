import React from 'react';
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	Grid,
	Grow,
	Radio,
	Typography,
	useMediaQuery,
} from '@mui/material';
import styles from './styles';
import { useTheme } from '@mui/material';
import { CheckoutCardColour } from '@models/checkout';

const PaletteCard: React.FC<{
	onSelected: () => void;
	colour: CheckoutCardColour;
	selected?: CheckoutCardColour;
}> = ({ colour, selected, onSelected }) => {
	const theme = useTheme();

	return (
		<Grow in timeout={{ enter: 500, exit: 250 }}>
			<Card css={styles(theme)} className={`palette-card palette-card-${colour}`}>
				<CardActionArea onClick={onSelected}>
					<CardContent className="palette-card-content">
						<Grid
							container
							direction="column"
							justifyContent="space-between"
							className="palette-card-content-grid"
							spacing={1}
						>
							<Grid item>
								<Grid container justifyContent="flex-end">
									<Grid item>
										<Radio
											color="default"
											className="palette-card-content-radio"
											checked={selected === colour}
											size="small"
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Typography
									className="palette-card-content-text"
									component="h4"
									variant="body1"
								>
									{(colour === 'black' && 'Graphite') ||
										(colour === 'silver' && 'Gunmetal') ||
										(colour === 'gold' && 'Gold')}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</CardActionArea>
			</Card>
		</Grow>
	);
};
export default PaletteCard;
