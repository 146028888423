import { Address } from '@models/address';
import shippingCodes from '@data/shipping-codes.json';
import { ShippingCode } from '@models/shipping';
import { Checkout, CheckoutShippingCode } from '@models/checkout';

export const cardCost = 29.99;

export const getAvailableShippingCodes = (address?: Address) => {
	const countrySpecificShippingCodesExist =
		!!address &&
		(shippingCodes as ShippingCode[]).some(({ country }) => country === address.country);
	return !!address
		? (shippingCodes as ShippingCode[]).filter(
				({ country }) =>
					(!country && !countrySpecificShippingCodesExist) || country === address.country
		  )
		: null;
};

export const getTotalCost = (shippingCodeId?: CheckoutShippingCode) => {
	const shippingCode = shippingCodes.find(({ id }) => id === shippingCodeId);
	return parseFloat((cardCost + (!!shippingCode ? shippingCode.price : 0)).toFixed(2));
};

export const getShippingCode = (shippingCodeId?: CheckoutShippingCode) => {
	return shippingCodes.find(({ id }) => id === shippingCodeId);
};

export const getShippingCost = (shippingCodeId?: CheckoutShippingCode) => {
	const shippingCode = shippingCodes.find(({ id }) => id === shippingCodeId);
	return !!shippingCode ? parseFloat(shippingCode.price.toFixed(2)) : null;
};
