import React, { useEffect, useRef, useState } from 'react';
import Layout from '@components/common/Layouts/Basic';
import styles from './styles';
import { Container, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { useLocation } from '@reach/router';

// import User from '@views/Checkout/User';
import Disclaimer from '@views/Checkout/Disclaimer';
import Colour from '@views/Checkout/Colour';
import Preview from '@views/Checkout/Preview';
import Customise from '@views/Checkout/Customise';
import Payment from '@views/Checkout/Payment';
import Login from '@views/Checkout/Login';
import Shipping from '@views/Checkout/Shipping';
import Review from '@views/Checkout/Review';
import Complete from '@views/Checkout/Complete';

import usePrevious from '@helpers/hooks/usePrevious';
import { makeGetRequest, makePostRequest, makePutRequest } from '@helpers/requests';
import { ADDRESSES, CHECKOUT, CHECKOUT_COMPLETE, CHECKOUTS } from '@helpers/api';
import {
	Checkout as CheckoutType,
	CheckoutBillingAddress,
	CheckoutCard,
	CheckoutShippingAddress,
	CheckoutShippingCode,
} from '@models/checkout';
import { useSnackbar } from '@components/common/Snackbar';
import { navigateParams } from '@helpers/common';
import { useSelector } from 'react-redux';
import { UserState } from '@store/user/types';
import Loader from '@components/common/Loader';
import { bakeLocalStorage, readLocalStorage } from '@helpers/storage';
import useDidUpdateEffect from '@helpers/hooks/useDidUpdateEffect';

export const CheckoutCardColourValidation = Yup.string()
	.oneOf(['black', 'silver', 'gold'], 'You must select a card color.')
	.nullable()
	.required()
	.label('UserCard type');
export const CheckoutCardCustomiseValidation = Yup.object().shape({
	lineOne: Yup.string().max(16, 'Maximum 16 characters').required().label('Line one'),
	lineTwo: Yup.string().max(24, 'Maximum 24 characters').nullable().label('Line two'),
});
export const CheckoutCardValidation = Yup.object().shape({
	colour: CheckoutCardColourValidation,
	lineOne: Yup.string().max(16, 'Maximum 16 characters').required().label('Line one'),
	lineTwo: Yup.string().max(24, 'Maximum 24 characters').nullable().label('Line two'),
});
export const CheckoutShippingValidation = Yup.object().shape({
	shippingAddressId: Yup.string().nullable().required().label('Shipping address'),
});
export const CheckoutPaymentValidation = Yup.object().shape({
	billingAddressId: Yup.string().nullable().required().label('Billing address'),
});

const Checkout: React.FC = () => {
	const theme = useTheme();
	const location = useLocation();
	const params = queryString.parse(location.search);
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));
	const checkoutId: any = params.checkoutId;
	const step: any = params.step;
	const [checkout, setCheckout] = useState<CheckoutType | null>(null);
	const [openSnackbar] = useSnackbar();
	const prevCheckout = usePrevious(checkout);
	const steps = [
		'disclaimer',
		'color',
		'customise',
		'login',
		'shipping',
		'review',
		'payment',
		'complete',
	];
	const { user } = useSelector(({ user }) => ({ user }));
	const [loading, setLoading] = useState<boolean>(true);
	const $steps: any = useRef<any>(null);

	const initialValues: {
		// user: CardUser;
		id?: string;
		card: CheckoutCard;
		billingAddressId: CheckoutBillingAddress;
		shippingAddressId: CheckoutShippingAddress;
		shippingCode: CheckoutShippingCode;
	} = {
		// user:
		// 	(params?.user === 'individual' && 'individual') ||
		// 	(params?.user === 'enterprise' && 'enterprise') ||
		// 	null,
		id: checkout?._id,
		card: {
			colour:
				(checkout?.card?.colour === 'black' && 'black') ||
				(checkout?.card?.colour === 'silver' && 'silver') ||
				(checkout?.card?.colour === 'gold' && 'gold') ||
				null,
			lineOne: checkout?.card?.lineOne ?? null,
			lineTwo: checkout?.card?.lineTwo ?? null,
		},
		billingAddressId: checkout?.billingAddressId ?? null,
		shippingAddressId: checkout?.shippingAddressId ?? null,
		shippingCode: checkout?.shippingCode ?? null,
	};

	const handleSubmit = async ({ braintree, ...values }, actions) => {
		console.log('handleSubmit()', values, actions, checkout);

		try {
			// save billing address before payment is completed
			await handleCheckoutSave(values);

			const { data: addresses } = await makeGetRequest(ADDRESSES);
			const billingAddress = addresses?.find(({ _id }) => _id === values.billingAddressId);
			// const shippingAddress = addresses?.find(({ _id }) => _id === values.shippingAddressId);

			const { nonce } = await braintree?.current?.instance?.requestPaymentMethod?.({
				amount: (0.0).toFixed(2),
				email: user?.email,
				billingAddress: {
					givenName: user?.firstName, // ASCII-printable characters required, else will throw a validation error
					surname: user?.lastName, // ASCII-printable characters required, else will throw a validation error
					phoneNumber: user?.phone?.number,
					streetAddress: billingAddress?.streetOne,
					extendedAddress: billingAddress?.streetTwo,
					locality: billingAddress?.city,
					region: billingAddress?.county,
					postalCode: billingAddress?.postcode,
					countryCodeAlpha2: billingAddress?.country,
				},
			});

			const { data: checkoutData } = await makePostRequest(CHECKOUT_COMPLETE(checkoutId), {
				paymentMethodNonce: nonce,
			});

			setCheckout(checkoutData);
			setTimeout(() => {
				navigateParams({
					...params,
					step: 'complete',
				});
			}, 500);
		} catch (error) {
			console.log({ error });
			if (error !== 'cancelled') {
				// clear payment methods
				braintree?.current?.instance?.clearSelectedPaymentMethod?.();
				openSnackbar(
					error?.message ??
						error?.errorMessage ??
						'An error occurred submitting your billing details.',
					'error'
				);
				throw error;
			}
		}
	};

	const handleInitialStep = async () => {
		const {
			shippingAddressId,
			card: { colour = null, lineOne = null } = {},
			stage: { complete = false } = {},
		} = checkout || {};

		const disclaimerAlphaAgreed = readLocalStorage(`disclaimer_alpha_${checkoutId}`) ?? false;

		let initialStep =
			(complete && 'complete') ||
			(!disclaimerAlphaAgreed && 'disclaimer') ||
			(shippingAddressId && colour && lineOne && user && 'review') ||
			(shippingAddressId && colour && lineOne && !user && 'login') ||
			(colour && lineOne && user && !shippingAddressId && 'shipping') ||
			(colour && lineOne && !user && 'login') ||
			(colour && !lineOne && 'customise') ||
			'colour';

		// if parameterised step exists and is before the determined step use that instead
		if (
			!!step &&
			initialStep !== 'complete' &&
			steps.indexOf(step) < steps.indexOf(initialStep) &&
			step !== 'disclaimer'
		)
			initialStep = step;

		await navigateParams({
			...params,
			checkoutId: checkout?._id,
			step: initialStep,
		});

		// loading is finished now the step has been finalised
		setLoading(false);
	};

	const handleCheckoutSave = async (values) => {
		try {
			if (!checkoutId) return;
			const { data: checkoutData } = await makePutRequest(CHECKOUT(checkoutId), {
				lineOne: values?.lineOne || checkout?.card?.lineOne,
				lineTwo: values?.lineTwo || checkout?.card?.lineTwo,
				colour: values?.colour || checkout?.card?.colour,
				billingAddressId: values?.billingAddressId,
				shippingAddressId: values?.shippingAddressId,
				shippingCode: values?.shippingCode,
			});

			setCheckout(checkoutData);
		} catch (error) {
			console.log(2, 'error', error);
			if (error !== 'cancelled') {
				openSnackbar(
					error?.errorMessage ?? 'An error occurred attempting to save your checkout.',
					'error'
				);
			}
		}
	};

	// useEffect(() => {
	// 	if (stepsOffset === null) {
	// 		setStepsOffset($steps?.current?.getBoundingClientRect?.()?.top ?? null);
	// 	}
	// }, [stepsOffset]);

	useEffect(() => {
		// ReactPixel.track('checkout-started');
		// handleStepsOffset();
		// window.addEventListener('resize', handleStepsOffset);

		// assign a checkoutId in URL on initial run, if it already exists pull it and set data into state
		(async () => {
			try {
				if (!!checkoutId) {
					const { data: checkoutData } = await makeGetRequest(CHECKOUT(checkoutId));

					// user is not allowed to load this checkout as this checkout belongs to a different user,
					// create a new checkout.
					if (!!checkoutData?.meta?.userId && user?._id !== checkoutData?.meta?.userId) {
						const { data: newCheckoutData } = await makePostRequest(CHECKOUTS);
						navigate(
							`?${queryString.stringify({
								...params,
								checkoutId: newCheckoutData._id,
							})}`,
							{ replace: true }
						);

						setCheckout(newCheckoutData);
						return;
					}

					setCheckout(checkoutData);
				} else {
					const { data: newCheckoutData } = await makePostRequest(CHECKOUTS);
					navigate(
						`?${queryString.stringify({
							...params,
							checkoutId: newCheckoutData._id,
						})}`,
						{ replace: true }
					);
					setCheckout(newCheckoutData);
				}
			} catch (error) {
				if (error !== 'cancelled') {
					openSnackbar(
						error?.errorMessage ??
							'An error occurred attempting to create your checkout.',
						'error'
					);
				}
			}
		})();
	}, []);

	useEffect(() => {
		// checkout has been created/retrieved
		if (!prevCheckout && !!checkout) handleInitialStep();
	}, [checkout]);

	useEffect(() => {
		// prevent users from navigating back to previous steps once the checkout has been completed
		if (checkout?.stage?.complete && step !== 'complete') {
			navigateParams({
				...params,
				step: 'complete',
			});
		}
	}, [step]);

	return (
		<Layout disableGutterBottom={!desktop}>
			<Container css={styles(theme)} disableGutters>
				<Formik
					validationSchema={Yup.object().shape({
						id: Yup.mixed().nullable(),
						// _id: Yup.mixed().nullable(),
						// user: CardUserValidation,
						card: CheckoutCardValidation,
						shippingAddressId: Yup.string()
							.nullable()
							.required()
							.label('Shipping address'),
						billingAddressId: Yup.string()
							.nullable()
							.required()
							.label('Billing address'),
						shippingCode: Yup.string().nullable().required().label('Shipping code'),
					})}
					onSubmit={handleSubmit}
					enableReinitialize
					initialValues={initialValues}
				>
					{({ setFieldValue, values }) => {
						const handleNextStep = async (values?: any) => {
							try {
								if (step === 'disclaimer') {
									bakeLocalStorage(`disclaimer_alpha_${checkoutId}`, true);
									await handleInitialStep();
								}

								if (step === 'colour') {
									await handleCheckoutSave(values);
									await navigateParams({ ...params, step: 'customise' });
								}

								if (step === 'customise') {
									await handleCheckoutSave(values);
									await navigateParams({
										...params,
										// if user exists, we can skip the login step completely
										step: user ? 'shipping' : 'login',
									});
								}

								if (step === 'login') {
									// user has now logged in, move to shipping
									await navigateParams({ ...params, step: 'shipping' });
								}

								if (step === 'shipping') {
									await handleCheckoutSave(values);
									await navigateParams({ ...params, step: 'review' });
								}

								if (step === 'review') {
									await navigateParams({ ...params, step: 'payment' });
								}
							} catch (error) {
								if (error !== 'cancelled') {
									openSnackbar(
										error?.errorMessage ??
											'An error occurred attempting to save your checkout changes.'
									);
								}
							}
						};

						const handlePrevStep = () => {
							if (step === 'colour') {
								navigateParams({ ...params, step: 'disclaimer' });
							}

							if (step === 'customise') {
								navigateParams({ ...params, step: 'colour' });
							}

							if (step === 'login') {
								navigateParams({ ...params, step: 'customise' });
							}

							if (step === 'shipping') {
								// skip past login step, as they are now logged in!
								navigateParams({ ...params, step: 'customise' });
							}

							if (step === 'review') {
								navigateParams({ ...params, step: 'shipping' });
							}

							if (step === 'payment') {
								navigateParams({ ...params, step: 'review' });
							}
						};

						return (
							<Grid
								container
								alignItems={desktop ? 'center' : undefined}
								className="checkout-wrapper"
							>
								<Grid className="checkout-steps" item xs={12} lg={6} ref={$steps}>
									{loading ? (
										<Loader />
									) : (
										<>
											{step === 'disclaimer' && (
												<Disclaimer onSubmit={handleNextStep} />
											)}

											{step === 'colour' && (
												<Colour
													onSubmit={handleNextStep}
													onBack={handlePrevStep}
												/>
											)}

											{step === 'customise' && (
												<Customise
													onSubmit={handleNextStep}
													onBack={handlePrevStep}
												/>
											)}

											{step === 'login' && (
												<Login
													onContinue={handleNextStep}
													onBack={handlePrevStep}
												/>
											)}

											{step === 'shipping' && (
												<Shipping
													onSubmit={handleNextStep}
													onBack={handlePrevStep}
												/>
											)}

											{step === 'review' && (
												<Review
													onContinue={handleNextStep}
													onBack={handlePrevStep}
												/>
											)}

											{step === 'payment' && (
												<Payment
													onSubmit={handleSubmit}
													onBack={handlePrevStep}
												/>
											)}

											{step === 'complete' && !!checkout && (
												<Complete checkout={checkout} />
											)}
										</>
									)}
								</Grid>
								<Grid item xs={12} lg={6}>
									<Preview step={step} steps={steps} {...values} />
								</Grid>
							</Grid>
						);
					}}
				</Formik>
			</Container>
		</Layout>
	);
};

export default Checkout;
